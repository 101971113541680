
import { defineComponent, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import LayoutHeader from './header/index.vue'
import LayoutMain from './main/index.vue'
import LayoutFooter from './footer/index.vue'
import Header from '@/components/header/index.vue'
import Footer from '@/components/footer/index.vue'
import Sidebar from '@/components/sidebar/index.vue'
export default defineComponent({
  name: 'Layout',
  components: {
    LayoutHeader,
    LayoutMain,
    LayoutFooter,
    Header,
    Footer,
    Sidebar
  },
  setup () {
    const { meta } = toRefs(useRoute())
    return { meta }
  }
})

import { App } from 'vue'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import { scrollBehavior } from './scrollBehavior'
import store from '@/store/index'
import { AppRouteRecordRaw, AppRouteMetaConfig } from '@/types'
import { getToken, getUserInfo } from '@/utils/cache'

// basic routes 基础路由
const basicRoutes: AppRouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/views/home/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      title: '登陆',
      hideFooter: true,
      hideHeader: true,
      hideSidebar: true
    }
  },
 
  {
    path: '/excellent',
    name: 'excellent',
    component: () => import('@/views/excellent/index.vue'),
    meta: {
      title: '生涯培优'
    }
  },
  {
    path: '/activityDetail',
    name: 'activityDetail',
    component: () => import('@/views/activityDetail/index.vue'),
    meta: {
      title: '活动详情'
    }
  },
  {
    path: '/teacherLesson',
    name: 'teacherLesson',
    component: () => import('@/views/teacherLesson/index.vue'),
    meta: {
      title: '名师讲堂'
    }
  },
  {
    path: '/teacherLesson/:id',
    name: 'teacherLesson',
    component: () => import('@/views/teacherLesson/index.vue'),
    meta: {
      title: '名师讲堂'
    }
  },
  {
    path: '/careerLesson/:id',
    name: 'careerLesson',
    component: () => import('@/views/careerLesson/index.vue'),
    meta: {
      title: '生涯讲堂'
    }
  },
  {
    path: '/college/:id',
    name: 'college',
    component: () => import('@/views/college/index.vue'),
    meta: {
      title: '大学展播'
    }
  },
  {
    path: '/practice/:id',
    name: 'practice',
    component: () => import('@/views/practice/index.vue'),
    meta: {
      title: '实践校'
    }
  },
  {
    path: '/newsList/:id',
    name: 'NewsList',
    component: () => import('@/views/newsList/index.vue'),
    meta: {
      title: '新闻资讯'
    }
  },
  {
    path: '/newsList',
    name: 'NewsList2',
    component: () => import('@/views/newsList/index.vue'),
    meta: {
      title: '新闻资讯2'
    }
  },
  {
    path: '/professor',
    name: 'Professor',
    component: () => import('@/views/professor/index.vue'),
    meta: {
      title: '生涯培训'
    }
  },
  {
    path: '/ability',
    name: 'Ability',
    component: () => import('@/views/ability/index.vue'),
    meta: {
      title: '学习力'
    }
  },
  {
    path: '/senior',
    name: 'senior',
    component: () => import('@/views/senior/index.vue'),
    meta: {
      title: '高级班'
    }
  },
  {
    path: '/middle',
    name: 'middle',
    component: () => import('@/views/middle/index.vue'),
    meta: {
      title: '中级班'
    }
  },
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('@/views/cooperation/index.vue'),
    meta: {
      title: '生涯云平台'
    }
  },
  {
    path: '/consult',
    name: 'Consult',
    component: () => import('@/views/consult/index.vue'),
    meta: {
      title: '生涯咨询'
    }
  },
  {
    path: '/study',
    name: 'Study',
    component: () => import('@/views/study/index.vue'),
    meta: {
      title: '生涯研学'
    }
  },
  // 赋能工程系列

  {
    path: '/capacity',
    name: 'Capacity',
    component: () => import('@/views/capacity/index.vue'),
    meta: {
      title: '赋能工程'
    }
  },
  {
    path: '/ambassador',
    name: 'Ambassador',
    component: () => import('@/views/ambassador/index.vue'),
    meta: {
      title: '赋能大使'
    }
  },
  {
    path: '/ambassadorDetail/:id',
    name: 'ambassadorDetail',
    component: () => import('@/views/ambassadorDetail/index.vue'),
    meta: {
      title: '赋能大使介绍'
    }
  },
  {
    path: '/engineer',
    name: 'Engineer',
    component: () => import('@/views/engineer/index.vue'),
    meta: {
      title: '赋能工程介绍'
    }
  },
  // end
  {
    path: '/career',
    name: 'Career',
    component: () => import('@/views/career/index.vue'),
    meta: {
      title: '生涯指导中心'
    }
  },
  {
    path: '/aboutUS',
    name: 'AboutUS',
    component: () => import('@/views/aboutUs/index.vue'),
    meta: {
      title: '关于我们'
    }
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    component: () => import('@/views/newsDetail/index.vue'),
    meta: {
      title: '新闻详情'
    }
  },
  {
    path: '/professorDetail/:id',
    name: 'professorDetail',
    component: () => import('@/views/professorDetail/index.vue'),
    meta: {
      title: '专家详情'
    }
  },
]

// auth routes 权限路由
const authRoutes: AppRouteRecordRaw[] = [
  {
    path: '/notice',
    component: () => import('@/views/notice/index.vue'),
    meta: {
      title: '我的通知',
      auth: true
    }
  },
  {
    path: '/user',
    redirect: '/user/binding',
    component: () => import('@/views/user/index.vue'),
    children: [
      {
        path: 'binding',
        component: () => import('@/views/user/binding/index.vue'),
        meta: {
          title: '账号绑定',
          auth: true
        }
      },
      {
        path: 'profile',
        component: () => import('@/views/user/profile/index.vue'),
        meta: {
          title: '个人资料',
          auth: true
        }
      },
      {
        path: 'log',
        component: () => import('@/views/user/log/index.vue'),
        meta: {
          title: '操作记录',
          auth: true
        }
      },
      {
        path: 'address',
        component: () => import('@/views/user/address/index.vue'),
        meta: {
          title: '收件地址',
          auth: true
        }
      }
    ]
  }
]

// full routes 全部路由
const routes = [
  ...basicRoutes,
  ...authRoutes
] as RouteRecordRaw[]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  scrollBehavior: scrollBehavior,
  routes: routes
})

router.beforeEach((to, from, next) => {
  // const meta = to.meta as AppRouteMetaConfig
  // document.title = meta.title ? `曹雪洋-${meta.title}` : '曹雪洋-学习网'
  document.title = '北京新生涯学业规划研究院-官方网站'
  next()
  // const token = getToken()
  // if (token) {
  //   const userInfo = getUserInfo()
  //   if (!userInfo.uid) {
  //     // store.dispatch('user/getInfo', token)
  //     store.dispatch('user/getInfo', token)
  //   }
  // }
  // if (meta && meta.auth) {
  //   token ? next() : next('/home')
  // } else {
  //   next()
  // }
})

export function setupRouter (app: App<Element>) {
  app.use(router)
}

export default router

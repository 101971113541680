
import { defineComponent, ref, onBeforeMount } from "vue";
import { getHeaderNav } from "@/api/common";
import { ERR_OK } from "@/api/config";
import { NavConfig } from "@/types";
export default defineComponent({
  name: "HeaderNavigation",
  data() {
    return {
      list: [
        { title: "首页", url: "/home" },
        { title: "生涯资讯", url: "/newsList/1" },
        { title: "师资培训", url: "/professor" },
        { title: "生涯云平台", url: "/cooperation" },
        { title: "生涯咨询", url: "/consult" },
        { title: "生涯研学", url: "/study" },
        { title: "生涯指导中心", url: "/career" },
        { title: "赋能工程", url: "/capacity" },
        { title: "关于我们", url: "/aboutUs" },
      ],
      a: 0,
    };
  },
  setup(store:any) {
    console.log(store,'111');
    
    // const navList = ref<NavConfig[]>([]);
    // onBeforeMount(async () => {
    //   const { code, data } = await getHeaderNav();
    //   if (code === ERR_OK && data) {
    //     navList.value = data;
    //   }
    // });
    // return { navList };
  },
  methods: {
    setActive(a: any) {
      // console.log(a);
      this.a = a;
    },
  },
});

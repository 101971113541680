
import { UserInfo } from '@/types'
import { computed, defineAsyncComponent, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
const AppDownload = defineAsyncComponent(() => import('./appDownload.vue'))
const UserCard = defineAsyncComponent(() => import('./userCard.vue'))
export default defineComponent({
  name: 'HeaderLoginArea',
  components: {
    // AppDownload,
    // UserCard
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const isBellHovering = ref(false)
    const userInfo = computed<UserInfo>(() => store.getters.userInfo)
    const handleLoginClick = (type: number) => {
      router.push({ path: '/login', query: { type } })
    }
    return { isBellHovering, userInfo, handleLoginClick }
  }
})
